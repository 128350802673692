// Root imports
import React, { useState } from "react";

// Style importe
import "../css/email.css";

// Server side imports
import AWS from "aws-sdk";

// Import html email templates
import mailForClient from "../html/mail_for_client.js";
import mailForSales from "../html/mail_for_sales.js";

// Local variables
const plans = {
  annual: { type: "Anual" },
  monthly: { type: "Mensual" },
  perHour: { type: "Por Horas" },
};

// Email form component
const EmailForm = (props) => {
  // Getting keys
  const planKeys = Object.keys(plans);

  //  Local state to handle user content
  const [userName, setName] = useState(null);
  const [userPhone, setPhone] = useState(null);
  const [userEmail, setEmail] = useState(null);
  const [comments, setComments] = useState("¡Me interesa la suscripción Anual!");
  const [selectedOption, setSelectedOption] = useState(planKeys[0]);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [selectedCity, setSelectedCity] = useState(null);
  const [colombiaCities, setColombiaCities] = useState(null);

  // Method to get the cities of a department
  const getColombiaCities = async (idDepartment) => {
    try {
      const response = await fetch(
        `https://api-colombia.com/api/v1/Department/${props.colombiaDepartments[idDepartment].id}/cities`
      );
      const data = await response.json();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setColombiaCities(data);
    } catch (error) {
      console.error("Error fetching Colombian cities data:", error);
    }
  };

  // On mount
  if (props.colombiaDepartments !== null && colombiaCities === null) {
    getColombiaCities(selectedDepartment);
  }

  // Method for sending emails
  const sendEmail = async () => {
    // Getting API version for backend of SES microservice
    const ses = new AWS.SES({ apiVersion: "2010-12-01" });

    // Getting html content for each destination user
    // For client
    const clientContent = mailForClient(userName);
    // for sales
    const salesContent = mailForSales(
      userName,
      userPhone,
      userEmail,
      comments,
      plans[selectedOption].type
    );

    // Email AWS SES structure for client email
    const clientParams = {
      Destination: {
        ToAddresses: [userEmail],
      },
      Message: {
        Body: {
          Html: {
            Data: clientContent,
          },
        },
        Subject: { Data: "¡Gracias por tu interés! - Cotización en camino" },
      },
      Source: "no-reply@rdc-prodes.com",
    };

    // Email AWS SES structure for sales email
    const salesParams = {
      Destination: {
        ToAddresses: ["j.parra@rdc-consultores.com"],
      },
      Message: {
        Body: {
          Html: {
            Data: salesContent,
          },
        },
        Subject: { Data: "Nueva persona interesada en ProDet" },
      },
      Source: "no-reply@rdc-prodes.com",
    };

    // Sending emails
    try {
      // To client
      await ses.sendEmail(clientParams).promise();
      // To sales
      await ses.sendEmail(salesParams).promise();
      // Close form
      handleClose();
      // If there is an error
    } catch (error) {
      console.error("Error al enviar el correo electrónico:", error);
    }
  };

  // Method for closing form
  const handleClose = () => {
    props.handleFormClose();
  };

  const handleSendMessage = () => {
    // Wpp phone number
    const phoneNumber = "573001447296";

    // Msg content
    const msgContent = `Hola 👋, me interesa ProDet
    Mi nombre es: ${userName}
    Mi número de celular es: ${userPhone}
    Mi correo electrónico es: ${userEmail}
    Trabajo en: ${colombiaCities[selectedCity].name}, ${props.colombiaDepartments[selectedDepartment].name}

    ${comments}`;

    // Construct the WhatsApp API URL
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      msgContent
    )}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappUrl, "_blank");
  };

  // Handle the change in department
  const handleDepartmentChange = (e) => {
    // Updating the value
    setSelectedDepartment(e.target.value);

    // Updating the cities
    getColombiaCities(e.target.value);
  };

  return (
    <div className="container">
      <div className="email-form">
        <div className="form-title">Ponte en contacto con nosotros</div>

        <form id="form-element">
          <div id="form-text">Ingresa tu nombre:</div>
          <input
            type="text"
            id="name-field"
            size="50"
            required
            placeholder="Tu nombre aquí"
            onChange={(e) => setName(e.target.value)}
          />

          <div id="form-text">Ingresa tu correo:</div>
          <input
            type="email"
            id="email-field"
            size="50"
            required
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
          />

          <div id="form-text">Ingresa tu celular:</div>
          <input
            type="tel"
            id="tel-field"
            size="50"
            required
            placeholder="+00 0000000000"
            onChange={(e) => setPhone(e.target.value)}
          />

          <div id="form-text">
            Selecciona el departamento en el que trabajas
          </div>
          <select
            id="plans-list"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
          >
            {props.colombiaDepartments !== null &&
              props.colombiaDepartments.map((department, index) => {
                return (
                  <option key={index} value={index}>
                    {department.name}
                  </option>
                );
              })}
          </select>

          <div id="form-text">Selecciona la ciudad en el que trabajas</div>
          <select
            id="plans-list"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            {colombiaCities !== null &&
              colombiaCities.map((city, index) => {
                return (
                  <option key={index} value={index}>
                    {city.name}
                  </option>
                );
              })}
          </select>

          <div id="form-text">
            Si deseas dejar algún comentario, hazlo a continuación:
          </div>
          <textarea
            rows="5"
            required
            id="text-field"
            placeholder="Deja tus comentarios"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>

          {/* <div id="form-text">
            Selecciona el plan que más se ajuste a tus necesidades
          </div> */}
          {/* <select
            id="plans-list"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            {planKeys.map((plan, index) => {
              return (
                <option key={index} value={plan}>
                  {plans[plan].type}
                </option>
              );
            })}
          </select> */}
          <div id="buttons">
            <div id="submit-button" onClick={handleSendMessage}>
              Enviar
            </div>
            <div id="close-button" onClick={handleClose}>
              Cerrar
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailForm;
