// Root imports
import React, { useState, useRef, useEffect } from "react";

// Style for the component
import "../css/main_page.css";

// WebSite components
import UpperContent from "./UpperContent";
import AttributesSection from "./AttributesSection";
import VideoComp from "./VideoComp";
import AdvantagesSection from "./AdvantagesSection";
import TestimonialsSection from "./TestimonialsSection";
import WhyProDetSection from "./whyProDetSection";
import PricingSection from "./PricingSection";
import Footer from "./Footer";
import EmailForm from "./EmailForm";

// Third party components
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Static content import
import logo from "../static/logo.svg";

// Buttons info
const buttonsInfo = {
  attributes: { text: "Atributos", type: "scroll" },
  video: { text: "Conoce más", type: "scroll" },
  opinions: { text: "Historia", type: "scroll" },
  advantages: { text: "Ventajas", type: "scroll" },
  whyProDes: { text: "Motivos", type: "scroll" },
  pricing: { text: "Planes", type: "scroll" },
  prodes: { text: "Ir a ProDet", type: "navigate" },
};

const MainPage = () => {
  // Component references
  const startSectionRef = useRef(null);
  const attributesSectionRef = useRef(null);
  const videoSectionRef = useRef(null);
  const advantagesSectionRef = useRef(null);
  const whyProDesRef = useRef(null);
  const opinionsRef = useRef(null);
  const pricesRef = useRef(null);

  // Local state
  const [isFormVisible, setVisibility] = useState(false); // email form visibility
  const [isHamMenuOn, setIsHamMenuOn] = useState(false); // Ham. menu visibility
  const [showTooltip, setShowTooltip] = useState(false);
  const [colombiaDepartments, setColombiaDepartments] = useState(null);
  const [animateCarrusel, setShouldAnimate] = useState(true);

  // Local variable
  const proDesLink = "https://www.rdc-prodes.com";
  const isMobile = useMediaQuery("(max-width:999px)");

  // Method to extract departments of Colombia
  const getColombiaDepartments = async () => {
    try {
      const response = await fetch(
        "https://api-colombia.com/api/v1/Department"
      );
      const data = await response.json();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setColombiaDepartments(data);
    } catch (error) {
      console.error("Error fetching Colombian cities data:", error);
    }
  };

  // Adding event listener to the mouse wheel
  const addMouseWheelListener = () => {
    window.addEventListener("wheel", handleMouseWheel);
  };

  // Method for activating animation by handling mouse wheel
  const handleMouseWheel = () => {
    setShouldAnimate(true)
  };

  // Method for opening email form and getting the departments of Colombia
  const handleFormOpen = () => {
    setVisibility(true);
    getColombiaDepartments();
  };

  // Method for closing email form
  const handleFormClose = () => {
    setVisibility(false);
    setColombiaDepartments(null);
  };

  // Method to scroll to a determinate section
  const scrollTo = (key, callback) => {
    // section variable
    let section;
    // get section by key
    if (key === "start") section = startSectionRef;
    if (key === "attributes") section = attributesSectionRef;
    if (key === "video") section = videoSectionRef;
    if (key === "advantages") section = advantagesSectionRef;
    if (key === "whyProDes") section = whyProDesRef;
    if (key === "opinions") section = opinionsRef;
    if (key === "pricing") section = pricesRef;

    // scroll to section
    if (section && section.current) {
      section.current.scrollIntoView({ behavior: "smooth" });
      // If there is a callback and it is a function, it will be executed after 1 second
      if (callback && typeof callback === "function") {
        setTimeout(callback, 1000);
      }
    }
  };

  // Generate button Method
  const genButton = (key, index) => {
    // Getting text and type
    const text = buttonsInfo[key].text;
    const type = buttonsInfo[key].type;

    // If OnClick will trigger navigate to ProDet GUI
    return type === "navigate" ? (
      <div
        key={index}
        className="prodet-button"
        onClick={() => window.open(proDesLink, "_blank")}
      >
        {text}
      </div>
    ) : (
      // Just a button to scroll into a section
      <div
        key={index}
        className="header-button"
        onClick={() => {
          setShouldAnimate(false);
          scrollTo(key);
        }}
      >
        {text}
      </div>
    );
  };

  // Sidebar menu with the navbar buttons on cell phones
  const HamburgerMenu = () => {
    return (
      <Drawer
        anchor="right"
        open={isHamMenuOn}
        onClose={() => setIsHamMenuOn(!isHamMenuOn)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "30vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "1.5rem",
            fontSize: "1rem",
            backgroundColor: "black",
            color: "var(--font-color)",
            padding: " 0 1rem",
            zIndex: "10",
          },
        }}
      >
        {/* Button to close the menu */}
        <div className="close-icon-ham-menu-container">
          <CloseIcon
            onClick={() => setIsHamMenuOn(!isHamMenuOn)}
            fontSize="large"
          />
        </div>
        {/* Rendering header buttons */}
        {Object.keys(buttonsInfo).map((key, index) =>
          buttonsInfo[key].type === "navigate" ? (
            <div
              key={index}
              className="ham-menu-item"
              onClick={() => (window.location.href = proDesLink)}
            >
              <span>{buttonsInfo[key].text}</span>
            </div>
          ) : (
            <div
              key={index}
              className="ham-menu-item"
              onClick={() => {
                setIsHamMenuOn(!isHamMenuOn);
                setShouldAnimate(false);
                scrollTo(key);
              }}
            >
              <span>{buttonsInfo[key].text}</span>
            </div>
          )
        )}
      </Drawer>
    );
  };

  // Method to generate header
  const genHeader = () => {
    // Callback for activate animation
    const handleScrollToStart = () => {
      setShouldAnimate(true);
    };

    // General header structures
    return (
      <div className="app-header">
        <div className="nav-bar-logo">
          {/* ProDet logo */}
          <img
            className="prodet-icon"
            src={logo}
            alt="logo_prodet"
            onClick={() => scrollTo("start", handleScrollToStart)}
          ></img>

          {/* Titles */}
          <div
            className="header-title-1"
            onClick={() => scrollTo("start", handleScrollToStart)}
          >
            ProDet
          </div>
        </div>

        <div className="nav-bar-items">
          {/* Rendering header buttons */}
          {Object.keys(buttonsInfo).map((key, index) => {
            return genButton(key, index);
          })}

          {/* Nav bar menu for cell phones */}
          <MenuIcon
            onClick={() => setIsHamMenuOn(!isHamMenuOn)}
            className="menu-cell-phones"
            fontSize="large"
          />
        </div>
      </div>
    );
  };

  const WppLink = (text) => (
    <a
      href="https://wa.me/573001447296"
      aria-label="Chat on WhatsApp"
      target="_blank"
      rel="noopener noreferrer"
      className="wpp-link"
    >
      <Tooltip
        title={<span className="tooltip-title">{text}</span>}
        placement="top"
        open={!isHamMenuOn ? showTooltip : false}
        disableInteractive
      >
        <Fab aria-label="whatsApp link" className="wpp-button">
          <WhatsAppIcon fontSize="large" className="wpp-icon" />
        </Fab>
      </Tooltip>
    </a>
  );

  // On mount effects
  useEffect(() => {
    // Effect to activate the tooltip
    const interval = setInterval(() => {
      setShowTooltip((prev) => !prev);
    }, 1500);

    // Activating carrusel animation
    addMouseWheelListener()

    // On dismount
    return () => {
      clearInterval(interval);
      window.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  return (
    // general container for the website
    <div className="main-page">
      {/* Generating header */}
      {genHeader()}

      {/* Hamburger menu for cell phones */}
      {isMobile ? HamburgerMenu() : ""}

      <div className="app-body">
        {/* Generating upper content (first description) */}
        <div ref={startSectionRef}>
          <UpperContent scrollTo={scrollTo} animate={animateCarrusel} />
        </div>

        <div ref={attributesSectionRef}>
          <AttributesSection />
        </div>

        <div ref={videoSectionRef}>
          <VideoComp />
        </div>

        <div ref={opinionsRef}>
          <TestimonialsSection />
        </div>

        <div ref={advantagesSectionRef}>
          <AdvantagesSection />
        </div>

        <div ref={whyProDesRef}>
          <WhyProDetSection />
        </div>

        <div ref={pricesRef}>
          <PricingSection handleFormOpen={handleFormOpen} />
        </div>

        <div className={`form ${isFormVisible ? "visible" : "hidden"}`}>
          <EmailForm
            handleFormClose={handleFormClose}
            colombiaDepartments={colombiaDepartments}
          />
        </div>

        <div>
          <Footer handleFormOpen={handleFormOpen} />
        </div>
      </div>
      {/* Wpp icon link */}
      {WppLink("¿Te interesa ProDet?\n¡Contáctanos!")}
    </div>
  );
};

export default MainPage;
